<template>
    <section class="white d-flex align-center" style="height: 100%">
        <v-container style="height: 90%" class="white">
            <div v-if="loading" class="d-flex flex-column align-center justify-center">
                <v-progress-linear indeterminate color="info" />
                <h3>Carregando avaliação Técnica</h3>
            </div>
            <template v-else>
                <v-row v-if="technicalEvaluation">
                    <v-col cols="12">
                        <v-row>
                            <!-- Verbo & Complemento -->
                            <v-col cols="12">
                                <div class="d-flex flex-column flex-md-row align-center justify-center justify-md-start">
                                    <v-list>
                                        <v-list-item two-line class="px-1">
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Verbo</v-list-item-subtitle>
                                                <v-list-item-title class="font-weight-medium text-h5">
                                                    {{ technicalEvaluation.verbo }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                    <v-list>
                                        <v-list-item two-line class="px-1">
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Complemento</v-list-item-subtitle>
                                                <v-list-item-title class="font-weight-medium text-h5">
                                                    {{ technicalEvaluation.complemento }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                    <v-list class="px-1">
                                        <v-list-item two-line class="px-1 pt-0">
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Objetivo Geral</v-list-item-subtitle>
                                                <v-list-item-title class="font-weight-regular"
                                                    v-if="technicalEvaluation && technicalEvaluation.objetivo">
                                                    {{ technicalEvaluation.objetivo |
                                                        processFloat(technicalEvaluation.tipo_unidade_medida)
                                                    }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                        <v-list
                            v-if="technicalEvaluation && technicalEvaluation.metas_individuais && technicalEvaluation.metas_individuais.length > 0"
                            class="pt-0 overflow-y-auto" height="40em">
                            <v-list-item v-for="meta in technicalEvaluation.metas_individuais" :key="meta.uuid"
                                class="my-3">
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium text-h6">
                                        {{ meta.colaborador.nome }}
                                    </v-list-item-title>
                                    <div class="d-flex flex-column align-start mx-2 mt-2">
                                        <p class="pa-0 mb-2">
                                            <span class="font-weight-light text-subtitle-2">Objetivo: </span>
                                            <span class="font-weight-regular"
                                                v-if="meta && meta.meta && meta.meta.objetivo">
                                                {{ meta.meta.objetivo |
                                                    processFloat(technicalEvaluation.tipo_unidade_medida) }}
                                            </span>
                                        </p>
                                        <v-text-field v-if="meta && meta.meta && meta.meta.alcancado !== null"
                                            :disabled="loadingButton" v-model="meta.meta.alcancado"
                                            v-mask="unidadesMedidas[technicalEvaluation.tipo_unidade_medida].mask"
                                            :prefix="unidadesMedidas[technicalEvaluation.tipo_unidade_medida].prefix" dense
                                            outlined label="Alcançado" hide-details></v-text-field>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="12">
                        <div class="d-flex align-center justify-center justify-md-start mt-4">
                            <v-btn color="success" :loading="loadingButton" @click="saveEvaluation()">Finalizar avaliação
                                <v-icon class="ml-2">mdi-check</v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <Invalid v-else></Invalid>
            </template>
        </v-container>
    </section>
</template>

<script>
import { computed, ref, onMounted } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils'
import { unMaskNumber } from "@/helpers";
import Invalid from '@/modules/avd/avaliacoes/components/avaliacaoComponents/Invalid.vue'

export default {
    name: 'AvaliacaoTecnica',
    components: {
        Invalid
    },
    setup() {
        // Variable
        const { route } = useRouter();
        const loading = ref(true);
        const loadingButton = ref(false);
        const unidadesMedidas = {
            RE: {
                mask: [
                    '#,##',
                    '##,##',
                    '###,###',
                    '#.###,##',
                    '##.###,##',
                    '###.###,##',
                    '#.###.###,##'
                ],
                prefix: 'R$'
            },
            DO: {
                mask: [
                    '#.##',
                    '##.##',
                    '###.###',
                    '#,###.##',
                    '##,###.##',
                    '###,###.##',
                    '#,###,###.##'
                ],
                prefix: 'US$'
            },
            UN: {
                mask: [
                    '#',
                    '##',
                    '###',
                    '#.###',
                    '##.###',
                    '###.###',
                    '#.###.###'
                ],
                prefix: 'UN'
            },
            PO: {
                mask: [
                    '#,##',
                    '##,##',
                    '###,##',
                ],
                prefix: '%'
            }
        }

        // Computed
        const technicalEvaluation = computed(() => store.getters['avdFormularios/getTechnicalEvaluations'])

        // LifeCycle
        onMounted(() => {
            const data = { batteryUuid: route.value.params.battery_uuid, managerUuid: route.value.params.uuid, metaUuid: route.value.params.meta_uuid }

            store.dispatch('avdFormularios/getFormTechnicalEvaluation', data).catch(() => {
                store.dispatch("snackBarModule/openSnackBar", {
                    color: "error",
                    timeout: 5000,
                    text: "Ocorreu um erro ao buscar pela avaliação. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
                });
            }).finally(() => {
                loading.value = false;
            })
        })


        // Function
        function processMetas() {
            if (!technicalEvaluation.value || technicalEvaluation.value.metas_individuais.length < 1) {

                store.dispatch("snackBarModule/openSnackBar", {
                    color: "error",
                    timeout: 3000,
                    text: "Houve um erro em processar as metas. Por favor, tente novamente mais tarde.",
                });

                return null;
            }

            let newArray = [];
            technicalEvaluation.value.metas_individuais.forEach(el => {

                let meta = {};

                Object.assign(meta, el)

                delete meta.colaborador;
                meta.meta.alcancado = unMaskNumber(meta.meta.alcancado, technicalEvaluation.value.tipo_unidade_medida)

                newArray.push(meta)
            })

            return newArray;
        }
        function saveEvaluation() {
            const data = {
                metas_individuais: processMetas()
            };

            store.dispatch('avdFormularios/saveFormTechnicalEvaluation', data).then(() => {
                store.dispatch("snackBarModule/openSnackBar", {
                    color: "success",
                    timeout: 3000,
                    text: "Avaliação concluída com sucesso.",
                });

                const params = { uuid: route.value.params.uuid, baterry_uuid: route.value.params.battery_uuid }

                this.$router.push({ name: 'avd-avaliacoes-ext', params: params })
            })
        }

        return {
            // Variable
            loading,
            loadingButton,
            unidadesMedidas,

            // Computed
            technicalEvaluation,

            // Function
            saveEvaluation
        }
    }
}
</script>